import React, { createContext, useEffect, useState } from "react";
import * as nextAuth from "next-auth/react";
import { setAuthToken } from "src/api/api";
import { FullSpinner } from "@components/LoadingSpinner";

export interface UserContext {
    userIsValid?: boolean;
}

export interface TokenContextProps {
    token?: string;
    loading: boolean;
    userContext: UserContext;
    setUserIsValid: (userIsValid: boolean) => void;
}

export const TokenContext = createContext<TokenContextProps>({
    loading: true,
    userContext: {},
    setUserIsValid: () => {
        return;
    },
});

export const TokenProvider: React.FC = ({ children }) => {
    const [userContext, setUserContext] = useState<UserContext>({});

    const [token, setToken] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);

    const { data: session, status } = nextAuth.useSession();

    const setUserIsValid = (userIsValid: boolean) => {
        setUserContext({ ...userContext, userIsValid });
    };

    const newAccessToken = session?.accessToken;

    useEffect(() => {
        if (newAccessToken) {
            setToken(newAccessToken);
            setAuthToken(newAccessToken);
        }

        setLoading(status === "loading" || false);
        if (session?.error === "RefreshAccessTokenError") {
            nextAuth.signIn("cognito");
        }
    }, [newAccessToken, status]);

    const context = {
        token: newAccessToken || token,
        loading,
        userContext,
        setUserIsValid,
    };

    return <TokenContext.Provider value={context}>{loading ? <FullSpinner /> : children}</TokenContext.Provider>;
};

export default TokenProvider;
