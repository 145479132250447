import React from "react";
import { SpinnerDotted, SpinnerDottedProps } from "spinners-react";

export type LoadingSpinnerProps = SpinnerDottedProps;

export const SPINNER_COLOUR_DEFAULT = "#7839AC";
export const SPINNER_COLOUR_WHITE = "#ffffff";

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
    size = 75,
    thickness = 140,
    color = SPINNER_COLOUR_DEFAULT,
    speed = 120,
}) => {
    const Component = SpinnerDotted;
    return (
        <div className="w-100 text-center">
            <Component size={size} thickness={thickness} color={color} speed={speed} />
            <span className="visually-hidden">Loading...</span>
        </div>
    );
};
