import React from "react";
import { AppProps } from "next/app";
import "@styles/app.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";
import { Provider as ReduxProvider } from "react-redux";
import { SessionProvider } from "next-auth/react";
import store from "@redux/store";
import Head from "next/head";
import TokenProvider from "@stores/TokenProvider";

function App({ Component, pageProps }: AppProps): JSX.Element {
    const queryClient = new QueryClient();
    return (
        <>
            <Head>
                <title>Sarah & Dom</title>
            </Head>
            <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps.dehydratedState}>
                    <SessionProvider session={pageProps.session}>
                        <TokenProvider>
                            <ReduxProvider store={store}>
                                <Component {...pageProps} />
                            </ReduxProvider>
                        </TokenProvider>
                    </SessionProvider>
                </Hydrate>
            </QueryClientProvider>
        </>
    );
}

export default App;
