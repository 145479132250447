import React from "react";
import { LoadingSpinner, LoadingSpinnerProps, SPINNER_COLOUR_WHITE } from "./LoadingSpinner";

export const FullSpinner: React.FC<LoadingSpinnerProps> = ({
    size = 75,
    thickness = 100,
    color = SPINNER_COLOUR_WHITE,
    speed = 100,
}) => {
    return (
        <div className="d-flex vh-100 vw-100 text-center align-middle justify-content-center align-items-center">
            <LoadingSpinner size={size} thickness={thickness} color={color} speed={speed} />
        </div>
    );
};
