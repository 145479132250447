import axios from "axios";

export interface UserCheckCodeResponse {
    codeValid: boolean;
}

// TODO: A huge hack to handle Next-Auth renewing auth tokens for now :( Fix this properly.
let currentAuthToken = "";

export const setAuthToken = (authToken: string): void => {
    currentAuthToken = authToken;
    setAxiosTokenInterceptor(currentAuthToken);
};

export const getAuthToken = (): string => {
    return currentAuthToken;
};

export const api = axios.create({
    timeout: 60000,
});

export const setAxiosTokenInterceptor = async (accessToken: string): Promise<void> => {
    api.interceptors.request.use(async (config) => {
        const requestConfig = config;
        if (accessToken) {
            requestConfig.headers = {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`,
            };
        }
        return requestConfig;
    });
};
